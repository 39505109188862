.react-pagination__item {
  align-items: center;
  color: #2b557c;
  background-color: #f5f5f5;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 30px;
  justify-content: center;
  width: 30px;
  font-weight: 500;
  font-family: 'Museo Sans';
  transition: all 0.2s;
  margin: 0 2px;
}

.react-pagination__item > a {
  width: 100%;
  text-align: center;
  outline: none;
}

.react-pagination__disabled-page {
  color: #c8c8c8;
}
.react-pagination__active {
  color: #fafafa;
  border: 1px solid #879aab;
  background-color: #879aab;
}

.react-pagination__item:not(.react-pagination__active):hover {
  color: #fafafa;
  border: 1px solid #019fb3;
  background-color: #019fb3;
}

.react-pagination__next,
.react-pagination__previous {
  font-size: 12px;
  height: 35px;
  width: 15px;
  margin: auto;
  border: none !important;
}
.react-pagination__next > a,
.react-pagination__previous > a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-pagination__pagination {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  padding-left: 0;
}

.react-pagination__next {
  margin-right: 10px;
  background-color: transparent !important;
}
.react-pagination__previous {
  margin-left: 10px;
  background-color: transparent !important;
}
